import React from 'react'
import { useTranslation } from 'react-i18next'

const Spinner = props => {
  const { t } = useTranslation()
  return (
    <div
      className="flex flex-row justify-center p-8"
      aria-label={t('accessibility.loading')}
    >
      <div
        style={{ borderTopColor: 'transparent' }}
        className={`w-12 h-12 border-4 border-primary border-solid rounded-full animate-spin`}
        aria-hidden="true"
      />
    </div>
  )
}

export default Spinner
