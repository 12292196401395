import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { find } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { AUDIO_TYPE_ICONS_SVG } from '../../../service/const'
import { ExhibitionContext } from '../ExhibitionProvider'

import Header from '../../../components/Header'
import Spinner from '../../../components/Spinner'
import ChapterNumber from '../../../components/ChapterNumber'
import HomeButton from '../../../components/HomeButton'
import ReactDocumentTitle from 'react-document-title'

const Chapters = () => {
  let params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { exhibition, loading } = useContext(ExhibitionContext)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (exhibition) {
      const intro = find(exhibition.acf?.intro, {
        guideType: params.guideType,
      })?.introText
      const chapters = exhibition.acf?.chapters
      const guideType = params.guideType
      const guideTypeIcon = AUDIO_TYPE_ICONS_SVG[params.guideType]

      if (!intro) {
        navigate(`/${params.slug}/`)
      }

      setData({ intro, chapters, guideType, guideTypeIcon })
    }
  }, [exhibition, navigate, params.guideType, params.slug, setData])

  return (
    <ReactDocumentTitle
      title={t('accessibility.document_title_choose_chapter', {
        exhibition: exhibition?.title?.rendered,
      })}
    >
      <>
        <Header
          title={exhibition?.title?.rendered}
          left={<HomeButton route={`/${params.slug}`} className="mr-4" />}
        />
        <main
          className="container px-5 py-5"
          aria-label={t('accessibility.page_content')}
          aria-busy={loading}
        >
          {loading || !data ? (
            <Spinner />
          ) : (
            <>
              <h1
                className="flex flex-row items-center justify-center text-lg font-bold mb-5"
                // aria-label={t('accessibility.headline')}
              >
                <span className="mr-2 fill-primary" aria-hidden="true">
                  {data?.guideTypeIcon}
                </span>
                {t(`guideTypes.${data.guideType}`).toUpperCase()}
              </h1>
              <div className="mb-5" aria-label={t('accessibility.intro')}>
                {data?.intro}
              </div>

              <button
                className="flex flex-row items-center justify-center w-full h-16 border-2 border-primary mb-5 bg-primary text-secondary hover:bg-secondary hover:text-primary"
                onClick={() => {
                  navigate(`1`)
                }}
                aria-label={t('accessibility.start_audioguide_tour')}
              >
                <FontAwesomeIcon
                  icon={solid('play')}
                  size="2xl"
                  className="mr-4"
                />
                <div className="text-lg font-bold" aria-hidden="true">
                  {t(`chapters.start_tour`).toUpperCase()}
                </div>
              </button>
              <hr className="-mx-5 border-t-2 mb-5" aria-hidden="true" />
              <h2
                className="text-center font-bold mb-5"
                // aria-label={t('accessibility.headline')}
              >
                {t('chapters.choose_chapter').toUpperCase()}
              </h2>
              {data?.chapters?.map((chapter, index) => {
                return (
                  <Link
                    to={`${index + 1}`}
                    key={`chapter-${index}`}
                    className=""
                    aria-label={t('accessibility.go_to_chapter_label', {
                      chapterNr: index + 1,
                      chapterTitle: chapter.chapterName,
                    })}
                  >
                    <div
                      className="grid grid-cols-[3fr_10fr_1fr] items-center mb-5"
                      aria-hidden="true"
                    >
                      <ChapterNumber number={index + 1} />
                      {/* <div>{index + 1}</div> */}
                      <div className="text-lg font-bold leading-tight">
                        {chapter.chapterName}
                      </div>
                      <div className="text-right">
                        <FontAwesomeIcon icon={solid('chevron-right')} />
                      </div>
                    </div>
                  </Link>
                )
              })}
            </>
          )}
        </main>
      </>
    </ReactDocumentTitle>
  )
}

export default Chapters
