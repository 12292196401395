const root = document.documentElement

export const applyTheme = theme => {
  Object.keys(theme).forEach(cssVar => {
    root.style.setProperty(cssVar, theme[cssVar])
  })
}

export const increaseFont = () => {
  const fontSize = getFontSize()
  root.style.setProperty('--theme-fontSize', `${fontSize + 2}px`)
}

export const decreaseFont = () => {
  const fontSize = getFontSize()
  root.style.setProperty('--theme-fontSize', `${fontSize - 2}px`)
}

export const getFontSize = () => {
  const fontSizeString =
    getComputedStyle(root).getPropertyValue('--theme-fontSize')
  const fontSize = parseInt(fontSizeString)
  return fontSize
}

export const switchFont = () => {
  const activeFont = getComputedStyle(root)
    .getPropertyValue('--theme-fontFamily')
    .trim()
  if (activeFont === 'Maax') {
    root.style.setProperty('--theme-fontFamily', 'Arial')
  } else {
    root.style.setProperty('--theme-fontFamily', 'Maax')
  }
}

export const createTheme = ({ primary, secondary }) => {
  return {
    '--theme-primary': primary,
    '--theme-secondary': secondary,
  }
}
