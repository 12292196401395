import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AUDIO_TYPE_ICONS_SVG } from '../../../service/const'

import Header from '../../../components/Header'
import { ExhibitionContext } from '../ExhibitionProvider'
import Spinner from '../../../components/Spinner'
import ReactDocumentTitle from 'react-document-title'

const AudioGuideTypes = ({ navigation }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { loading, exhibition } = useContext(ExhibitionContext)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (exhibition) {
      const intro = exhibition.acf?.intro
      const guideTypes = []
      intro.forEach(element => {
        guideTypes.push(element.guideType)
      })

      setData({ guideTypes })
    }
  }, [exhibition])

  return (
    <ReactDocumentTitle
      title={t('accessibility.document_title_choose_type', {
        exhibition: exhibition?.title?.rendered,
      })}
    >
      <>
        <Header title={exhibition?.title.rendered || t('audio_guide')} />
        <main
          className="container px-5 pb-5"
          aria-label={t('accessibility.page_content')}
          aria-busy={loading}
        >
          <h1
            className="my-8 text-center font-bold"
            // aria-label={t('accessibility.headline')}
          >
            {t('exhibition.choose_guide_type').toUpperCase()}
          </h1>
          {loading || !data ? (
            <Spinner />
          ) : (
            <>
              {data?.guideTypes?.map(type => {
                return (
                  <button
                    key={type}
                    className="w-full h-28 border-2 border-primary mb-5 hover:bg-primary hover:text-secondary hover:fill-secondary fill-primary"
                    onClick={() => {
                      if (
                        type === 'easy_english' &&
                        i18n.resolvedLanguage !== 'en'
                      ) {
                        i18n.changeLanguage('en')
                      }
                      navigate(`chapters/${type}`)
                    }}
                    aria-label={t(
                      'accessibility.choose_audio_guide_type_label',
                      {
                        type: t(`guideTypes.${type}`),
                      },
                    )}
                  >
                    {AUDIO_TYPE_ICONS_SVG[type]}
                    <div className="text-lg font-bold" aria-hidden="true">
                      {t(`guideTypes.${type}`).toUpperCase()}
                    </div>
                  </button>
                )
              })}
            </>
          )}
        </main>
      </>
    </ReactDocumentTitle>
  )
}

export default AudioGuideTypes
