import React from 'react'
import { useTranslation } from 'react-i18next'

const ChapterNumber = ({ number, className, big }) => {
  const { t } = useTranslation()
  return (
    <div
      className={`triangle relative ${className} ${
        big ? 'big' : ''
      } flex justify-center items-center`}
      aria-label={t('accessibility.chapter_nr', {
        chapterNr: number,
      })}
    >
      <div
        className={`absolute top-[0.9rem] leading-none ${
          big ? '!top-[1.6rem]' : ''
        }`}
        aria-hidden="true"
      >
        {number}
      </div>
    </div>
  )
}

export default ChapterNumber
