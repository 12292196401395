import React, { useState, createContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import api from '../../service/api'

export const ExhibitionsContext = createContext()

export const ExhibitionsProvider = props => {
  const [exhibitions, setExhibitions] = useState()
  const [loading, setLoading] = useState(true)
  let params = useParams()
  const { i18n } = useTranslation()

  useEffect(() => {
    setLoading(true)
    // const exhibitionParams = new URLSearchParams([
    //   ['slug', params.slug],
    //   ['_fields', 'id,slug,title,acf'],
    //   ['acf_format', 'standard'],
    //   ['lang', i18n.resolvedLanguage],
    // ])
    api
      .get('/exhibitions')
      .then(resp => {
        if (resp.data) {
          setExhibitions(resp.data)
        }

        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  return (
    <ExhibitionsContext.Provider value={{ exhibitions, loading }}>
      {props.children}
    </ExhibitionsContext.Provider>
  )
}
