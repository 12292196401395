import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../../components/Header'

const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  return (
    <>
      <Header title={t('audio_guide')} />
      <div className="container px-5 pb-5">
        <div className="my-24 text-center font-bold">
          {t('site_not_found').toUpperCase()}
          <button
            className="mt-6 flex flex-row items-center justify-center font-bold w-full h-16 border-2 border-primary bg-primary text-secondary hover:bg-secondary hover:text-primary"
            onClick={() => {
              navigate(`/${params.slug}`)
            }}
            aria-label={t('accessibility.go_home')}
          >
            {t('back').toUpperCase()}
          </button>
        </div>
      </div>
    </>
  )
}

export default NotFound
