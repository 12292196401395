import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Header from '../../../components/Header'
import HomeButton from '../../../components/HomeButton'
import { ExhibitionContext } from '../ExhibitionProvider'
import { useTranslation } from 'react-i18next'
import ChapterNumber from '../../../components/ChapterNumber'
import Spinner from '../../../components/Spinner'
import ReactDocumentTitle from 'react-document-title'

const NextChapter = () => {
  //   const [chapterNr, setChapterNr] = useState()
  //   const [chapterTitle, setChapterTitle] = useState()
  const [data, setData] = useState()
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { exhibition, loading } = useContext(ExhibitionContext)

  useEffect(() => {
    if (exhibition) {
      const chapters = exhibition.acf?.chapters
      const chapterNr = parseInt(params.chapter) + 1
      const chapterTitle = chapters[chapterNr - 1]?.chapterName
      setData({ chapterNr, chapterTitle })
    }
  }, [exhibition, params.chapter])

  return (
    <ReactDocumentTitle
      title={t('accessibility.document_title_next_chapter', {
        exhibition: exhibition?.title?.rendered,
      })}
    >
      <>
        <Header
          left={<HomeButton route={`/${params.slug}`} className="mr-4" />}
        />
        <main aria-label={t('accessibility.page_content')}>
          <Link
            to={`/${params.slug}/chapters/${params.guideType}`}
            className="flex flex-row items-center justify-center my-2"
            aria-label={t('accessibility.go_back_chapter')}
          >
            <FontAwesomeIcon icon={solid('list-ol')} className="mr-2" />
            <div className="font-bold" aria-hidden="true">
              {t('chapter.back_to_chapters').toUpperCase()}
            </div>
          </Link>
          <hr className="border-t-2" aria-hidden="true" />
          <div className="container text-center px-5 pb-5">
            {loading || !data ? (
              <Spinner />
            ) : (
              <div className="mt-20 flex h-[40vh] flex-col justify-between">
                {data?.chapterTitle && data?.chapterNr ? (
                  <>
                    <h1 className="font-bold text-lg">{t('next_chapter')}</h1>
                    <ChapterNumber
                      number={data?.chapterNr}
                      className="mx-auto"
                    />
                    <h2 className="font-bold text-xl">{data?.chapterTitle}</h2>
                    <button
                      className="flex flex-row items-center font-bold justify-center w-full h-16 border-2 border-primary bg-primary text-secondary hover:bg-secondary hover:text-primary"
                      onClick={() => {
                        navigate(
                          `/${params.slug}/chapters/${params.guideType}/${data?.chapterNr}`,
                        )
                      }}
                      aria-label={t('accessibility.next_chapter_label')}
                    >
                      {t('forward').toUpperCase()}
                    </button>
                  </>
                ) : (
                  <>
                    <h1 className="font-bold text-lg">{t('end_guide')}</h1>
                    <h2>{t('end_guide_text')}</h2>
                    <button
                      className="flex flex-row items-center justify-center font-bold w-full h-16 border-2 border-primary bg-primary text-secondary hover:bg-secondary hover:text-primary"
                      onClick={() => {
                        navigate(`/${params.slug}`)
                      }}
                      aria-label={t('accessibility.go_home')}
                    >
                      {t('back').toUpperCase()}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </main>
      </>
    </ReactDocumentTitle>
  )
}

export default NextChapter
