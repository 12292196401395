import {
  faPerson,
  faPersonCane,
  faPersonWalkingWithCane,
} from '@fortawesome/pro-solid-svg-icons'

import { ReactComponent as VisuallyHandicappedIcon } from '../assets/images/audiodescriptive.svg'
import { ReactComponent as EasyToReadIcon } from '../assets/images/easy-to-read.svg'

export const AUDIO_TYPE_OPTIONS = [
  { name: 'normal', icon: faPerson },
  { name: 'easy_to_read', icon: faPersonCane },
  { name: 'visually_handicapped', icon: faPersonWalkingWithCane },
  { name: 'easy_english', icon: faPersonCane },
]

export const AUDIO_TYPE_ICONS = {
  normal: faPerson,
  easy_to_read: faPersonCane,
  visually_handicapped: faPersonWalkingWithCane,
  easy_english: faPersonCane,
}

export const AUDIO_TYPE_ICONS_SVG = {
  visually_handicapped: (
    <VisuallyHandicappedIcon
      className="svg-inline--fa fa-2x"
      aria-hidden="true"
    />
  ),
  easy_to_read: (
    <EasyToReadIcon className="svg-inline--fa fa-2x" aria-hidden="true" />
  ),
}
