import React, { useEffect } from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Exhibitions from './routes/Exhibitions/Exhibitions'
import Exhibition from './routes/Exhibition/Exhibition'
import NotFound from './routes/NotFound/NotFound'

import { applyTheme } from './themes/utils'
import baseTheme from './themes/base'
import ReactDocumentTitle from 'react-document-title'
import { useTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import { ExhibitionsProvider } from './routes/Exhibitions/ExhibitionsProvider'

function App() {
  useEffect(() => {
    applyTheme(baseTheme)
  }, [])

  const { t, i18n } = useTranslation()

  return (
    <ReactDocumentTitle title={t('accessibility.document_title_main')}>
      <ExhibitionsProvider>
        <Helmet htmlAttributes={{ lang: i18n.resolvedLanguage }} />
        <Router>
          <Routes>
            <Route path="/" element={<Exhibitions />} />
            <Route path="/:slug/*" element={<Exhibition />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ExhibitionsProvider>
    </ReactDocumentTitle>
  )
}

export default App
