import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Spinner from '../../components/Spinner'
import api from '../../service/api'
import { ExhibitionsContext } from './ExhibitionsProvider'

const Exhibitions = () => {
  const { t } = useTranslation()
  const { exhibitions, loading } = useContext(ExhibitionsContext)

  const { i18n } = useTranslation()

  return (
    <>
      <Header title={t('audio_guide')} />
      <main
        className="container p-5"
        aria-label={t('accessibility.page_content')}
      >
        {loading || !exhibitions ? (
          <Spinner />
        ) : (
          exhibitions[i18n.resolvedLanguage].map(exhibition => {
            return (
              <Link
                className="flex items-center justify-center text-lg font-bold w-full h-28 border-2 border-primary mb-5 hover:bg-primary hover:text-secondary hover:fill-secondary fill-primary"
                key={exhibition.slug}
                to={`/${exhibition.slug}`}
              >
                {exhibition.title}
              </Link>
            )
          })
        )}
      </main>
    </>
  )
}

export default Exhibitions
