import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ExhibitionContext } from '../ExhibitionProvider'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Header from '../../../components/Header'
import HomeButton from '../../../components/HomeButton'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import Spinner from '../../../components/Spinner'
import ChapterNumber from '../../../components/ChapterNumber'
import AudioPlayer from '../../../components/AudioPlayer'
import ReactDocumentTitle from 'react-document-title'
import { InnerImageZoom } from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'

const Chapter = () => {
  let params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { exhibition, loading } = useContext(ExhibitionContext)
  const [data, setData] = useState(null)
  const [loadingImg, setLoadingImg] = useState(false)

  useEffect(() => {
    if (exhibition) {
      const chapters = exhibition.acf?.chapters
      const chaptersLength = chapters.length
      const chapterNr = parseInt(params.chapter)
      const chapter = chapters[chapterNr - 1]
      const headerImage = chapter?.headerImage
      const title = chapter?.chapterName
      const audioTrack = find(chapter?.audioTracks, {
        guideType: params.guideType,
      })

      setLoadingImg(false)

      if (!chapter || !headerImage || !audioTrack) {
        navigate(`/${params.slug}/chapters/${params.guideType}/`)
      } else {
        setData({ headerImage, title, audioTrack, chaptersLength, chapterNr })
      }
    }
  }, [exhibition, params.chapter, params.guideType])

  const nextChapter = () => {
    setLoadingImg(true)
    navigate(
      `/${params.slug}/chapters/${params.guideType}/${data.chapterNr + 1}`,
    )
  }

  const prevChapter = () => {
    setLoadingImg(true)
    navigate(
      `/${params.slug}/chapters/${params.guideType}/${data.chapterNr - 1}`,
    )
  }

  return (
    <ReactDocumentTitle
      title={t('accessibility.document_title_chapter', {
        chapterNr: data?.chapterNr,
        exhibition: exhibition?.title?.rendered,
      })}
    >
      <>
        <Header
          left={<HomeButton route={`/${params.slug}`} className="mr-4" />}
        />
        <main aria-label={t('accessibility.page_content')} aria-busy={loading}>
          <Link
            to={`/${params.slug}/chapters/${params.guideType}`}
            className="flex flex-row items-center justify-center my-2"
            aria-label={t('accessibility.go_back_chapter')}
          >
            <FontAwesomeIcon icon={solid('list-ol')} className="mr-2" />
            <div className="font-bold" aria-hidden="true">
              {t('chapter.back_to_chapters').toUpperCase()}
            </div>
          </Link>
          <hr className="border-t-2" aria-hidden="true" />
          {loading || !data ? (
            <Spinner />
          ) : (
            <>
              <div className="container mb-5">
                {!loadingImg && (
                  <InnerImageZoom
                    src={data.headerImage.url}
                    imgAttributes={{ alt: data.headerImage.alt }}
                    className="max-h-48 object-cover w-full max-h-48 sm:max-h-full"
                  />
                )}
              </div>
              <div className="container px-5 pb-5" role="presentation">
                <ChapterNumber
                  number={data.chapterNr}
                  className="mb-5 mx-auto"
                  big
                />
                <h1 className="text-xl text-center font-bold mb-5">
                  {data.title}
                </h1>
                <div className="w-full">
                  <AudioPlayer
                    url={data.audioTrack.audioFile}
                    prev={prevChapter}
                    next={nextChapter}
                    disablePrev={data.chapterNr === 1}
                    disableNext={data.chapterNr === data.chaptersLength}
                    onEnded={nextChapter}
                  />
                </div>
                <hr className="border-t-2 -mx-5 my-5" aria-hidden="true" />

                <h2 className="mb-2 text-center font-bold">
                  {t('chapter.transcription').toUpperCase()}
                </h2>
                <div
                  className="whitespace-pre-wrap"
                  aria-label={t('accessibility.transcription')}
                >{`${data.audioTrack.transcription}`}</div>
              </div>
            </>
          )}
        </main>
      </>
    </ReactDocumentTitle>
  )
}

export default Chapter
