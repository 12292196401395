import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const HomeButton = ({ route, className }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <button
      onClick={() => {
        navigate(route)
      }}
      className={`hover:opacity-60 ${className}`}
      aria-label={t('accessibility.go_home')}
    >
      <FontAwesomeIcon icon={solid('house')} size="2x" />
    </button>
  )
}

export default HomeButton
