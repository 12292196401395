import { forEach } from 'lodash'
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import api from '../../service/api'
import { ExhibitionsContext } from '../Exhibitions/ExhibitionsProvider'

export const ExhibitionContext = createContext()

export const ExhibitionProvider = props => {
  const [exhibition, setExhibition] = useState()
  const [loading, setLoading] = useState(false)
  let params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation()
  const prevLang = useRef(i18n.resolvedLanguage)

  const { exhibitions, loading: exhibitionsLoading } =
    useContext(ExhibitionsContext)

  useEffect(() => {
    setLoading(true)
    const exhibitionParams = new URLSearchParams([
      ['slug', params.slug],
      ['_fields', 'id,slug,title,acf'],
      ['acf_format', 'standard'],
      ['lang', i18n.resolvedLanguage],
    ])
    api
      .get(`exhibition`, {
        params: exhibitionParams,
      })
      .then(resp => {
        if (resp.data) {
          setExhibition(resp.data[0])
        }

        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }, [params.slug])

  useEffect(() => {
    let activeExhibition
    Object.entries(exhibitions).forEach(([key, value]) => {
      const tmpExhibition = value.find(item => item.slug === params.slug)
      if (tmpExhibition) {
        activeExhibition = tmpExhibition
      }
    })

    const id = activeExhibition?.lang_ids?.[i18n.resolvedLanguage]
    const newExhibition = exhibitions?.[i18n.resolvedLanguage].find(
      item => item.id === id,
    )

    prevLang.current = i18n.resolvedLanguage

    if (newExhibition?.slug) {
      const newPath = location.pathname.replace(
        params.slug,
        newExhibition?.slug,
      )
      navigate(newPath, { replace: true })
    }
  }, [i18n.resolvedLanguage])

  return (
    <ExhibitionContext.Provider value={{ exhibition, loading }}>
      {props.children}
    </ExhibitionContext.Provider>
  )
}
